.app {
    position: relative;
}

.main-menu {
    position: absolute;
    z-index: 20;
    top:0;
}

.navbar-expand-md  .navbar-nav a {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #B2E219 !important;
    border-bottom:none !important; 
    text-decoration: none;
}


.main-menu .navbar-nav .dropdown-menu.show {
    border-radius: 0;
    top: 75%;
    background: rgba(255, 255, 255, 0.6);
    border: 1px solid #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}


.main-menu .navbar-nav a.dropdown-item {
    color: #122051 !important;
    padding: 15px 16px;
    text-align: left;
}

.main-menu .navbar-nav a.dropdown-item a{
    color: #122051 !important;
}
.main-menu .navbar-nav a.dropdown-item a:hover{
    color: #B2E219 !important;
}

hr.dropdown-divider {
    border-top: 1px solid #BEBEBE;
    width: 90%;
    margin: auto;
}

.main-menu .navbar-nav a:hover {
    color: #ffffff;
} 

.dropdown-item:hover {
    color: #122051 !important;
    background: transparent;
}

/* offcanvas */

.offcanvas.offcanvas-end {
    width: 100%;
    /* background: rgb(15, 28, 78);
    background-image: url('../images/headerbg.webp');
    background-repeat: no-repeat;
    background-size: cover; */
}


.offcanvas-body .justify-content-end.mobilebiew.navbar-nav {
    align-items: center;
}

button.navbar-toggler.collapsed {
    border: none;
    box-shadow:none
}


span.navbar-toggler-icon {
    color: #B2E219 !important;
    background-image: url('../images/toggler-icon.webp');
    width: 1.2em;
    height: 1.2em;
}

.offcanvas-header .btn-close {
    background-image: url('../images/close-icon.webp');
    margin-right: 30px;
    opacity: 1;
}

.offcanvas a.nav-link {
    color: #ffffff;
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    padding: 30px 0px;
    border-bottom: 1px solid rgba(190, 190, 190, 0.5);
}


@media only screen   and (min-device-width : 320px)  and (max-device-width : 767px)  
{
    .main-menu .navbar {
        display: flex;
        justify-content: space-between;
        padding-top: 0px;
    }

    .main-menu .navbar a.navbar-brand img {
        max-width: 170px;
    }

    .offcanvas-header .offcanvas-title img {
      max-width: 170px;
    }

    .offcanvas-header {
        padding-left: 0px;
        padding-right: 0px;
    }

    .offcanvas.offcanvas-end {
        width: 100%;
        background: rgb(15, 28, 78);
        background-image: url('../images/headermenubg-mb.webp');
        background-repeat: no-repeat;
        background-size: cover;
    }    

    .offcanvas-body .justify-content-end.mobilebiew.navbar-nav {
        align-items: unset;
    }

    .offcanvas-body .btn-box {
        margin-top: 100px;
        padding: 26px 0px;
        border-top: 1px solid rgba(190, 190, 190, 0.5);
    }

    .btn-box button{

        /* max-width: 219px !important;
        max-height: 50px !important; */
        font-family: 'Clash Display';
        font-style: normal;
        font-weight: 600;
        font-size: 20px !important;
        line-height: 20px !important;
        text-align: center;
        letter-spacing: 0.02em;
        padding-top: 25px;
        padding-bottom:25px;
        color: #122051;
        padding: 20px 45px;
    }

    .offcanvas a {
        color: #ffffff !important;
        font-family: 'Gotham';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        padding: 26px 0px;
        text-decoration: none;
        /* border-bottom: 1px solid rgba(190, 190, 190, 0.5); */
    }

    .offcanvas .borderbottom{
        border-bottom: 1px solid rgba(190, 190, 190, 0.5);
    }
    .bordertop{
        border-top: 1px solid rgba(190, 190, 190, 0.5);
    }
    /* .offcanvas.offcanvas-end {
        width: 100%;
        background: rgb(15, 28, 78);
        background: url('../images/about-bg.webp') no-repeat top top;
    } */
    /* .mobilebiew a{
        font-family: 'Gotham';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #B2E219 !important;
        border-bottom:none !important; 
        text-decoration: none;
    }
     */

}



@media only screen   and (min-device-width : 768px)  and (max-device-width : 1023px)  {
    .main-menu {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .main-menu .desktop {
        display: block;
    }

    .main-menu .mobile {
        display: none;
    }

    .navbar-brand img {
        max-width: 210px;
    }

    .offcanvas-body .navbar-nav {
        gap: 1.5rem !important;
        align-items: center;
    }

    .main-menu .navbar-nav .communitymargin {
        margin-top: 0px;
    }

    
    .offcanvas a {
        font-size: 12px !important;
        line-height: 14.5px !important;
        padding: 20px 0px;
        /* border-bottom: 1px solid rgba(190, 190, 190, 0.5); */
    }
}



/*  */

@media (min-width: 768px) {
    .offcanvas.offcanvas-end.show  .offcanvas-header .offcanvas-title img {
    max-width: 170px;
  }

  .offcanvas.offcanvas-end.show  .offcanvas-header {
      padding-left: 0px;
      padding-right: 0px;
  }

  .offcanvas.offcanvas-end.show {
      width: 100%;
      background: rgb(15, 28, 78);
      background-image: url('../images/headermenubg-mb.webp');
      background-repeat: no-repeat;
      background-size: cover;
  }    
 
  .offcanvas.offcanvas-end.show  .offcanvas-body .justify-content-end.mobilebiew.navbar-nav {
      align-items: unset;
  }

  .offcanvas.offcanvas-end.show  .offcanvas-body .btn-box {
      margin-top: 100px;
      padding: 26px 0px;
      border-top: 1px solid rgba(190, 190, 190, 0.5);
  }

  .offcanvas.offcanvas-end.show .btn-box button{

      font-family: 'Clash Display';
      font-style: normal;
      font-weight: 600;
      font-size: 20px !important;
      line-height: 20px !important;
      text-align: center;
      letter-spacing: 0.02em;
      padding-top: 25px;
      padding-bottom:25px;
      color: #122051;
      padding: 20px 45px;
  }

  .offcanvas.offcanvas-end.show a {
      color: #ffffff !important;
      font-family: 'Gotham';
      font-style: normal;
      font-weight: 700;
      font-size: 16px !important;
      line-height: 19px !important;
      padding: 26px 0px;
      text-decoration: none;
  }

  .offcanvas.offcanvas-end.show .borderbottom{
      border-bottom: 1px solid rgba(190, 190, 190, 0.5);
  } 
}
/*  */

/* For 1024 Resolution */
@media (min-width: 1024px) and (max-width: 1280px) {
    
    .main-menu {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .navbar-brand img {
        max-width: 210px;
    }

    .offcanvas-body .navbar-nav {
        gap: 1.5rem !important;
        align-items: center;
    }

    .main-menu .navbar-nav .communitymargin {
        margin-top: 0px;
    }

    
    .offcanvas a {
        font-size: 12px;
        line-height: 14.5px;
        padding: 20px 0px;
        /* border-bottom: 1px solid rgba(190, 190, 190, 0.5); */
    }
    
}

@media (min-width: 1080px) and (max-width: 1280px) {
    .main-menu {
        padding-left: 2.5rem !important;
        padding-right: 2.5rem !important;
    }
}

@media (min-width: 1280px) and (max-width: 1360px) {
    .navbar-brand img {
        max-width: 240px;
    }

    .offcanvas a {
        font-size: 12.8px;
        line-height: 15.2px;
        padding: 20.8px 0px;
        /* border-bottom: 1px solid rgba(190, 190, 190, 0.5); */
    }
}

@media (min-width: 1360px) and (max-width: 1440px) {
    .navbar-brand img {
        max-width: 280px;
    }

    .offcanvas a {
        font-size: 13.6px;
        line-height: 15.15px;
        padding: 20.8px 0px;
        /* border-bottom: 1px solid rgba(190, 190, 190, 0.5); */
    }
}


@media (min-width: 1440px) and (max-width: 1680px) {
    .navbar-brand img {
        max-width: 300px;
    }

    .offcanvas a {
        font-size: 14.4px;
        line-height: 17.1px;
        padding: 23.4px 0px;
        /* border-bottom: 1px solid rgba(190, 190, 190, 0.5); */
    }
}

@media (min-width: 1680px) and (max-width: 1919px) {
    .navbar-brand img {
        max-width: 320px;
    }

    .offcanvas a {
        font-size: 15.2px;
        line-height: 18.05px;
        padding: 24.7px 0px;
        /* border-bottom: 1px solid rgba(190, 190, 190, 0.5); */
    }
}


@media (min-width: 1919px) {
    .navbar-brand img {
        max-width: 350px;
    }

    .offcanvas a {
        font-size: 14.4px;
        line-height: 17.1px;
        padding: 23.4px 0px;
        /* border-bottom: 1px solid rgba(190, 190, 190, 0.5); */
    }
}


@media only screen and (min-width: 1371px) and (max-width: 1605px) {}


@media only screen and (min-width: 1680px) and (max-width: 1919px) {}

@media only screen and (min-width: 1920px) {}


