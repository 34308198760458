* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
   url("./assets/Gotham-Bold.otf") format("truetype");
  font-weight: 700;
 }

@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
   url("./assets/Gotham-Medium.otf") format("truetype");
  font-weight: 500;
 }

.ms-20pr {
  margin-left: 20%;
}

.applynow-btn {
  text-align: center;
  padding: 32px 67px;
  background: #B2E219;
  border-radius: 80px;
  font-family: 'Clash Display';
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #122051;
}

.contact-btn {
  text-align: center;
  padding: 32px 67px;
  background: #B2E219;
  border-radius: 80px;
  font-family: 'Clash Display';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  color: #122051;
}

button {
  border: none;
  outline: none;
  box-shadow: none;
}

.blank {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: white;
  z-index: 20;
}

.headergif {
  width: 80%;
  margin: auto;
}

.mt-100 {
  margin-top: 100px;
}

.mt-50 {
  margin-top: 50px;
}

.px-5p {
  padding-left: 5%;
  padding-right: 5%;
}

.pt-5p {
  padding-top:5%;
}

.pt-10p {
  padding-top:10%;
}

.desktop  {
  display: block;
}

.mobile, .mobile-flex {
  display: none;
}

@media only screen   
and (min-device-width : 320px)   
and (max-device-width : 768px)  
{
  .desktop, .desktop-flex {
    display: none;
  }
  
  .mobile {
    display: block;
  }

  .mobile-flex {
    display: flex;
  }


}