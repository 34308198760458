/* Exclusive club Benefits */

.exclusivebenefit {
  overflow-x: hidden;
}

.exclusiveclub p.numheading{
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 22px;
  /* identical to box height, or 105% */
  text-transform: uppercase;
  color: #122051;
}

.exclusivebenefit h3.heading {
  /* padding: 0% 0 0 5%; */
  font-family: 'Clash Display';
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 59px;
  letter-spacing: 0.04em;
  color:#122051;
  padding-top: 80px;
}

.exclusivebenefit{
  margin-bottom: 120px;
  padding-bottom: 30px;
}

.exclusiveclub p.exclusive-para{
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 28px;
  /* or 133% */
  /* text-transform: lowercase; */
  color: #122051;
  margin-top: -10px;
}

.fixrow.row {
  align-items: center;
}

.exclusiveclub .para-margin .para-box {
  max-width: 380px;
  width: 100%;
}

.exclusivebenefit .exclusive-mobile-view{
  display: none;
}
.exclusivebenefit .mobileview-footer {
  display: none;
}

.exclusiveclub.container img {
  max-width: 130px;
}

/* For 420 Resolution */  
@media only screen  and (min-device-width : 320px) and (max-device-width : 420px)  { 

  .exclusivebenefit .exclusive-mobile-view{
    display: block;
  }
  .exclusivebenefit .mobileview-footer {
    display: block;
  }

.exclusivebenefit .exclusive-desktop-view{
display: none;
} 
.exclusivebenefit .exclusive-mobile-view img{
width: 40%;
height: 120px;
/* margin-right: -40px; */
padding-left: 20px;

}
.exclusivebenefit .exclusive-mobile-view .margindiv{
margin-left: -140px;
padding-left: -30px;
}
/* .exclusivebenefit .mobile-view{
padding:0%;
margin: 0%;
} */
.exclusivebenefit .exclusive-mobile-view .numheading{
font-family: 'Gotham';
font-style: normal;
font-weight: 700;
font-size: 13px;
line-height: 14px;
/* or 57% */

text-transform: uppercase;

color: #122051;
}
.exclusivebenefit .exclusive-mobile-view .numpara{
font-family: 'Gotham';
font-style: normal;
font-weight: 700;
font-size: 13px;
line-height: 14px;
/* or 57% */
/* padding-top: 20px; */
/* text-transform: uppercase; */

color: #122051;
}
.mobileview-footer .footer-heading h3 {
font-family: 'Clash Display';
font-style: normal;
font-weight: 600;
font-size: 32px;
line-height: 22px;
/* or 69% */
word-spacing: 0.3em;

text-align: center;
letter-spacing: 0.02em;

color: #122051;
}

.mobileview-footer .footer-heading img {
/* width: 30px; */
height: 12px;
}

.mobileview-footer .contact-btn {

font-weight: 600;
font-size: 20px !important;
line-height: 20px;
text-align: center;
letter-spacing: 0.02em;
padding: 20px 30px !important;
color: #122051;

}
.exclusivebenefit{
margin-bottom: 70px;
padding-bottom: 0px;
}

/*.exclusivebenefit .numheading,.exclusivebenefit .exclusive-para{
font-weight: 700 !important;
font-size: 14px !important;
line-height: 15px !important;
} */


.exclusive-mobile-view .mobile-view-heading {
  padding-top: 0px !important;
  font-family: 'Clash Display';
  font-style: normal;
  font-weight: 600;
  font-size: 22px !important;
  line-height: 28px !important;
  text-align: center;
  letter-spacing: 0.02em !important;
  color: #BEBEBE !important;
  margin-top: -30px;
}


}

@media only screen  and (min-device-width : 421px) and (max-device-width : 767px)  { 
  .exclusivebenefit .exclusive-mobile-view{
    display: block;
  }
  .exclusivebenefit .mobileview-footer {
    display: block;
  }

.exclusivebenefit .exclusive-desktop-view{
 display: none;
} 
.exclusivebenefit .exclusive-mobile-view img{
 width: 40%;
 height: 120px;
 /* margin-right: -40px; */
 padding-left: 20px;

}
.exclusivebenefit .exclusive-mobile-view .margindiv{
 margin-left: -140px;
 padding-left: -30px;
}
/* .exclusivebenefit .mobile-view{
 padding:0%;
 margin: 0%;
} */
.exclusivebenefit .exclusive-mobile-view .numheading{
 font-family: 'Gotham';
 font-style: normal;
 font-weight: 700;
 font-size: 14px;
 line-height: 14px;
 /* or 57% */
 
 text-transform: uppercase;
 
 color: #122051;
}
.exclusivebenefit .exclusive-mobile-view .numpara{
 font-family: 'Gotham';
 font-style: normal;
 font-weight: 700;
 font-size: 14px;
 line-height: 14px;
 /* or 57% */
 /* padding-top: 20px; */
 /* text-transform: uppercase; */
 
 color: #122051;
}
.mobileview-footer .footer-heading h3 {
 font-family: 'Clash Display';
 font-style: normal;
 font-weight: 600;
 font-size: 32px;
 line-height: 22px;
 /* or 69% */
 word-spacing: 0.3em;

 text-align: center;
 letter-spacing: 0.02em;

 color: #122051;
}

.mobileview-footer .footer-heading img {
 /* width: 30px; */
 height: 12px;
}

.mobileview-footer .contact-btn {

 font-weight: 600;
 font-size: 20px !important;
 line-height: 20px;
 text-align: center;
 letter-spacing: 0.02em;
padding: 20px 30px !important;
 color: #122051;

}
.exclusivebenefit{
 margin-bottom: 70px;
 padding-bottom: 0px;
}

/*.exclusivebenefit .numheading,.exclusivebenefit .exclusive-para{
 font-weight: 700 !important;
font-size: 14px !important;
line-height: 15px !important;
} */


.exclusive-mobile-view  .mobile-view-heading {
  padding-top: 0px;
  font-size: 24px !important;
  line-height: 28px !important;
  text-align: center;
  margin-top: -30px;
}

}


@media only screen  and (min-device-width : 768px) and (max-device-width : 1023px)  { 

  .exclusivebenefit .mobileview-footer {
    display: block;
  }

  .exclusivebenefit h3.heading {
    font-size: 35px;
    line-height: normal;
    text-align: center;
    width: 400px;
    margin: auto;
  }

  .exclusiveclub .para-margin .para-box {
    margin-left: 15px;
  }

  .exclusiveclub p.numheading ,
  .exclusiveclub p.exclusive-para {
    font-size: 16px;
    line-height: normal;
  }

  .exclusiveclub.container img {
    /* max-width: 90px; */
    max-height: 130px;
  }
}

 /* For 420 Resolution */

 @media  (min-device-width: 1024px)  and (max-device-width: 1366px) {
  .fixrow{
    align-items: center;
    /* margin-top: 0px;
    padding-top: 10px; */
  }
  .fixrow img{
    width: 100%;
  }
 .fixrow .para-margin{
    margin-top: 25px !important; 
    /* align-items: baseline;
    margin: 0px; */
  }
  .exclusiveclub p.numheading{
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 19px;
    text-transform: uppercase;
    color: #122051;
  }
  .exclusiveclub p.exclusive-para{
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    font-size: 17.8px;
    line-height: 24px;
    /* or 133% */
    /* text-transform: lowercase; */
    color: #122051;
    margin-top: -15px;
  }
  .exclusivebenefit h3.heading {
    font-size: 41px;
    line-height: 50px;
  }

 } 

 @media only screen and (min-width: 1920px) {
  .exclusiveclub p.numheading{
  font-size: 24px;
  line-height: 25px;
}
.exclusivebenefit h3.heading {
  font-size: 51px;
  line-height: 62px;
}
.exclusiveclub p.exclusive-para{
  
  font-size: 24px;
  line-height: 31px;
 
}}




/* For 1024 Resolution */


@media (min-width: 1024px) and (max-width: 1280px) {
  
  .exclusivebenefit h3.heading {
    font-size: 38.4px;
    line-height: 47.2px;   
  }  

  .exclusiveclub p.numheading, .exclusiveclub p.exclusive-para {
   
    font-size: 16.8px;
    line-height: 20.8px;
    
  }

  .desktop.ex-memberbenefits .applynow-btn {
      font-size: 24px;
      line-height: 27.2px;
      padding:22px 40px;
  }

}


@media only screen and (min-width: 1280px) and (max-width: 1360px) {
  .exclusivebenefit h3.heading {
    font-size: 40.8px;
    line-height: 50.15px;   
  }  

  .exclusiveclub p.numheading, .exclusiveclub p.exclusive-para {
   
    font-size: 17.85px;
    line-height: 22.1px;
  }
  .desktop.ex-memberbenefits .applynow-btn {
    font-size: 28px;
    line-height: 27.2px;
    padding:25px 50px;
  }
}

@media only screen and (min-width: 1360px) and (max-width: 1600px) {
  .exclusivebenefit h3.heading {
    font-size: 43.2px;
    line-height: 53.1px;   
  }  

  .exclusiveclub p.numheading, .exclusiveclub p.exclusive-para {
   
    font-size: 18.9px;
    line-height: 23.4px;
  }

  .desktop.ex-memberbenefits .applynow-btn {
    font-size: 25.2px;
    line-height: 30.6px;
    padding:28px 60px;  
  }

}


@media only screen and (min-width: 1600px) and (max-width: 1919px) {
  .exclusivebenefit h3.heading {
    font-size: 45.6px;
    line-height: 56.05px;   
  }  

  .exclusiveclub p.numheading, .exclusiveclub p.exclusive-para {
   
    font-size: 19.95px;
    line-height: 24.7px;
  }

  .desktop.ex-memberbenefits .applynow-btn {
    font-size: 26.6px;
    line-height: 32.3px;
    padding:30px 63.65px;
  }
}

@media only screen and (min-width: 1920px) {
  .exclusivebenefit h3.heading {
    font-size: 52px;
    line-height: 62px;
   
  }  
  .exclusiveclub p.numheading, .exclusiveclub p.exclusive-para {
   
    font-size: 24px;
    line-height: 29px;
    
  }
}
