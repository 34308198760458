.col-md-6.left-side.col {
    /* padding: 0% 0 0 5%; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.vision-section .left-side .vision h3 {
    font-family: 'Clash Display';
    font-style: normal;
    font-weight: 600;
    font-size: 96px;
    line-height: 118px;
    letter-spacing: 0.02em;
    color: #BEBEBE;
}

.vision-section .left-side p {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 42px;
    color: #122051;
    max-width: 650px;
}

.col-md-6.right-side {
    text-align: center;
}
/* For 480 Resolution */  
@media only screen  and (min-device-width : 320px) and (max-device-width : 640px)  { 


    .vision-section .mobile-flex .vision {
        transform: translateY(50px);
    }

    .vision-section .mobile-flex .vision h3 {
        font-family: 'Clash Display';
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 39px;
        letter-spacing: 0.02em;
        color: #BEBEBE;
    }

    .vision-section .mobile-flex .vision h3 span img {
        height: 11px;
        width: 23px;
        margin: 0px 2px;
    }

    .vision-section .mobile-flex .content-box img {
        width: 75%;
        margin: auto;
    }


    .vision-section .left-side p {

        font-family: 'Gotham';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #122051;
    }
} 


/* For 640 Resolution */  
@media only screen and (min-device-width : 481px) and (max-device-width : 767px)  {

    
    .vision-section .mobile-flex .vision {
        transform: translateY(50px);
    }

    .vision-section .mobile-flex .vision h3 {
        font-family: 'Clash Display';
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 39px;
        letter-spacing: 0.02em;
        color: #BEBEBE;
    }

    .vision-section .mobile-flex .vision h3 span img {
        height: 11px;
        width: 23px;
        margin: 0px 2px;
    }

    .vision-section .mobile-flex .content-box img {
        width: 75%;
        margin: auto;
    }


    .vision-section .left-side p {

        font-family: 'Gotham';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #122051;
    }


}  


/* For 1024 Resolution */  
@media only screen   
and (min-device-width : 768px)   
and (max-device-width : 1023px)  
{ /* STYLES GO HERE */

    .desktop-flex {
        display: none;
    }
    
    .mobile-flex {
        display: block;
    }

    
    .vision-section .mobile-flex .vision {
        transform: translateY(50px);
    }

    .vision-section .mobile-flex .vision h3 {
        font-family: 'Clash Display';
        font-style: normal;
        font-weight: 600;
        font-size: 76px;
        line-height: normal;
        letter-spacing: 0.02em;
        color: #BEBEBE;
    }

    .vision-section .mobile-flex .vision h3 span img {
        /* height: 35px; */
        width: 60px;
        margin: 0px 2px;
    }

    .vision-section .mobile-flex .content-box img {
        width: 50%;
        margin: auto;
    }


    .vision-section .left-side p {

        font-size: 22.4px;
        line-height: 33.6px;
        margin: auto;
    }

} 
 

@media (min-width: 1024px) and (max-width: 1280px) {
    
    .vision-section .left-side .vision h3 {
        font-size: 76.8px;
        line-height: 94.4px;
    }
    
    .vision-section .left-side p {
        font-size: 22.4px;
        line-height: 33.6px;
    }
    .vision-section .left-side .vision h3 img {
        max-height: 35px;
    }
    
}


@media (min-width: 1281px) and (max-width: 1360px) {
    
    .vision-section .left-side .vision h3 {
        font-size: 81.6px;
        line-height: 100px;
    }
    
    .vision-section .left-side p {
        font-size: 23.8px;
        line-height: 35.4px;
    }
    .vision-section .left-side .vision h3 img {
        max-height: 37px;
    }

}


@media only screen and (min-width: 1361px) and (max-width: 1440px) {
    

    .vision-section .left-side .vision h3 {
        font-size: 86.4px;
        line-height: 106.2px;
    }
    
    .vision-section .left-side p {
        font-size: 25.2px;
        line-height: 37.8px;
        font-size: 23.8px;
        line-height: 35.4px;
        
    }
    .vision-section .left-side .vision h3 img {
        max-height: 39px;
    }
}


@media only screen and (min-width: 1441px) and (max-width: 1600px) {
    

    .vision-section .left-side .vision h3 {
        font-size: 86.4px;
        line-height: 106.2px;
    }
    
    .vision-section .left-side p {
        font-size: 25.2px;
        line-height: 37.8px;
    }
    .vision-section .left-side .vision h3 img {
        max-height: 39px;
    }
}



@media only screen and (min-width: 1601px) and (max-width: 1919px) {
    
    .vision-section .left-side .vision h3 {
        font-size: 91.2px;
        line-height: 112.1px;
    }
    
    .vision-section .left-side p {
        font-size: 26.6px;
        line-height: 39.9px;
    }    
    .vision-section .left-side .vision h3 img {
        max-height: 40px;
    }

}

@media (min-width: 1024px){
    section.vision-section {
        padding: 20px 0;
    }
}