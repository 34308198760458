header.contact-header {
    width: 100%;
    height: 100%;
    /* min-height: 100vh; */
    background: linear-gradient(187deg, rgba(0, 0, 0, 1) 5.67%, rgba(0, 0, 0, 0.488542) 25.88%, rgba(0, 0, 0, 0) 60.38%);
    background: url('../images/header-contact.webp') no-repeat top left;
    background-size: contain;
    position: relative;
    background: none;
    z-index: 2;
    /* margin-bottom: 35px; */
}

header.contact-header .bg,
header.contact-header .bg img {
    width: 100%;
    max-width: 100vw;
    height:auto;
}

header.contact-header .hero {
    position: absolute;
    height: 100%;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);

}



header.contact-header .hero-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

header.contact-header .hero-text {
    position: relative;
    text-align: center;
}

header.contact-header .hero-text h2 {
    font-family: 'Clash Display';
    font-style: normal;
    font-weight: 600;
    font-size: 75px;
    line-height: 70px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #BEBEBE;
}

header.contact-header .hero-text span img {
    width: 42px;
    margin: 0 5px;
}

header.contact-header .hero-text p {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 29px;
    text-align: center;
    color: #FFFFFF;
}

header.contact-header .hero-text h3 {
    font-family: 'Clash Display';
    font-style: normal;
    font-weight: 600;
    font-size: 96px;
    line-height: 118px;
    text-align: center;
    letter-spacing: 0.01em;
    align-self: center;
    color: #FFFFFF;
}

/* header.header-home .navbar-nav a {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #B2E219;
} */

header.contact-header .text-slide {
    width: auto;
    overflow: hidden;
    white-space: nowrap;
    box-sizing: content-box;
    transform-origin: center;
    padding: 0px 0px;
    height: 110px;
    /* object-fit: cover; */
}

header.contact-header .text-slide-box {
    overflow: hidden;
}

header.contact-header .text-slider {
    display: flex;
    vertical-align: top;
    justify-content: center;
    min-height: 130px;
    /* gap: 20px; */
}


header.contact-header .text-slider.gap {
    gap: 20px;
}

header.contact-header .text-slider img {
    height: 100%;
    width: auto;
    align-self: center;
}
.contact-header .contactmobile-viewheading,
.contactmobile-viewpara {
    display: none;
}

/* For 420 Resolution */
@media only screen and (min-device-width : 320px) and (max-device-width : 420px) {
   
    .contact-header .contactmobile-viewheading,
    .contactmobile-viewpara {
        display: block;
    }

    header.contact-header .hero {
        min-height: 350;
        height: 100%;
        top:70%;
        height: 100%;
        top: 70%;
        padding: 5%;
        max-width: 400px;
        padding: 15% 20px 0px !important;
    }
    
    header.contact-header .hero-text {

        height: 100%;
        position: relative;
        top: 0% !important;
    }

    header.contact-header .hero-text h2 {
        font-size: 26px !important;
        line-height: 112.5% !important;
        word-spacing: normal;
        letter-spacing: 1.26px !important;
    }

    header.contact-header {
        width: 100%;
        height: 100%;
        min-height: 350px;
        background: linear-gradient(187deg, rgba(0, 0, 0, 1) 5.67%, rgba(0, 0, 0, 0.488542) 25.88%, rgba(0, 0, 0, 0) 60.38%);
        background: url('../images/about-bg.webp') no-repeat top left;
        background-size: cover;
    }
    .contact-header .contactdesktop-viewheading,
    .contactmobile-view,
    .contactdesktop-viewpara {
        display: none;
    }

    
    .contact-header .contactmobile-viewpara {
        font-family: 'Gotham';
        font-style: normal;
        font-weight: 700;
        font-size: 10px !important;
        line-height: 15px !important;
        text-align: center;
        color: #FFFFFF;
        position: relative;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }

    .contact-header .contactmobile-viewheading {
        font-weight: 600 !important;
        font-size: 22px !important;
        line-height: 27px !important;
        /* or 112% */

        text-align: center;
        letter-spacing: 0.01em !important;
    }

    .contact-header .hero-text img {
        width: 16px !important;
        height: 10px;
    }
}
@media only screen and (min-device-width : 421px) and (max-device-width : 767px) {
   
    .contact-header .contactmobile-viewheading,
    .contactmobile-viewpara {
        display: block;
    }

    header.contact-header .hero {
        height: 100%;
        top: 80%;
        padding: 5%;
        max-width: 450px;
        padding: 5% 20px !important;
    }

    header.contact-header .hero-text {

        height: 100%;
        position: relative;
        top: 0% !important;
    }

    header.contact-header .hero-text h2 {
        font-size: 30px;
        line-height: 112.5%;
        word-spacing: normal;
        letter-spacing: 1.30px;

    }

    header.contact-header {
        width: 100%;
        height: 100%;
        min-height: 400px;
        background: linear-gradient(187deg, rgba(0, 0, 0, 1) 5.67%, rgba(0, 0, 0, 0.488542) 25.88%, rgba(0, 0, 0, 0) 60.38%);
        background: url('../images/about-bg.webp') no-repeat top left;
        background-size: cover;
    }
    .contact-header .contactdesktop-viewheading,
    .contactmobile-view,
    .contactdesktop-viewpara {
        display: none;
    }
    
    .contact-header .contactmobile-viewheading {
        font-family: 'Clash Display';
        font-style: normal;
        font-weight: 600;
        font-size: 26px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.01em;
        color: #BEBEBE;
      
    }

    .contact-header .contactmobile-viewpara {
        font-family: 'Gotham';
        font-style: normal;
        font-weight: 700;
        font-size: 12px !important;
        line-height: 17px !important;
        text-align: center;
        color: #FFFFFF;
        position: relative;
        padding-top: 10px !important;
    }

   

    .contact-header .hero-text img {
        width: 16px !important;
        height: 10px;
    }
}



/* For 1024 Resolution */
@media only screen and (min-device-width : 768px) and (max-device-width : 1023px) {
    /* STYLES GO HERE */
    .contact-header .contactmobile-viewheading,
    .contactmobile-viewpara {
        display: block;
    }

    header.contact-header .hero {
        height: 100%;
        top: 80%;
        padding: 5%;
        max-width: 450px;
        padding: 5% 20px !important;
    }

    header.contact-header .hero-text {

        height: 100%;
        position: relative;
        top: 0% !important;
    }

    header.contact-header .hero-text h2 {
        font-size: 30px;
        line-height: 112.5%;
        word-spacing: normal;
        letter-spacing: 1.30px;

    }

    header.contact-header {
        width: 100%;
        height: 100%;
        min-height: 400px;
        background: linear-gradient(187deg, rgba(0, 0, 0, 1) 5.67%, rgba(0, 0, 0, 0.488542) 25.88%, rgba(0, 0, 0, 0) 60.38%);
        background: url('../images/about-bg.webp') no-repeat top left;
        background-size: cover;
    }
    .contact-header .contactdesktop-viewheading,
    .contactmobile-view,
    .contactdesktop-viewpara {
        display: none;
    }
    
    .contact-header .contactmobile-viewheading {
        font-family: 'Clash Display';
        font-style: normal;
        font-weight: 600;
        font-size: 26px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.01em;
        color: #BEBEBE;
      
    }

    .contact-header .contactmobile-viewpara {
        font-family: 'Gotham';
        font-style: normal;
        font-weight: 700;
        font-size: 12px !important;
        line-height: 17px !important;
        text-align: center;
        color: #FFFFFF;
        position: relative;
        padding-top: 10px !important;
    }

   

    .contact-header .hero-text img {
        width: 16px !important;
        height: 10px;
    }



}

/* @media (min-width: 1024px) and (max-width: 1370px) {
    

    header.contact-header .hero-text h2 {
        font-size: 51px;
        line-height: 55.25px;
    }
    header.contact-header .hero-text p {
        font-size: 18px;
        line-height: 25px;
    }
    
}
@media (min-width: 1920px){
    header.contact-header .hero-text h2 {
        font-size: 66px;
        line-height:72px;
    }
    header.contact-header .hero-text p {
        font-size: 24px;
        line-height: 32px;
    }
}
 */


/* For 1024 Resolution */
@media (min-width: 1024px) and (max-width: 1280px) {
    
    header.contact-header .hero {
        top: 65%;
    }

    header.contact-header .hero-content {
        justify-content: space-between;
        height: 80%;
    }
    
    header.contact-header .hero-text h2 {
        font-size: 48px;
        line-height: 52px;
    }

    header.contact-header .hero-text p {
        font-size: 16.8px;
        line-height: 23.2px;
      
    }
}


@media (min-width: 1281px) and (max-width: 1360px) {
   
    header.contact-header .hero {
        top: 60%;
    }

    header.contact-header .hero-content {
        justify-content: space-between;
        height: 85%;
    }

    
    header.contact-header .hero-text h2 {
        font-size: 51px;
        line-height: 55.25px;
    }

    header.contact-header .hero-text p {
        font-size: 17.85px;
        line-height: 24.65px;
    }
}


@media  (min-width: 1361px) and (max-width: 1600px) {

    header.contact-header .hero-content {
        justify-content: space-between;
        height: 85%
    }

    header.contact-header .hero-content {
        justify-content: space-around;
        height: 100%;
    }

    header.contact-header .hero {
        top: 55%;
    }

    header.contact-header .hero-text h2 {
        font-size: 54px;
        line-height: 58.5px;
    }

    header.contact-header .hero-text p {
        font-size: 18.9px;
        line-height: 26.1px;
    }

}


@media only screen and (min-width: 1601px) and (max-width: 1919px) {

    header.contact-header .hero {
        top: 50%;
    }

    header.contact-header .hero-content {
        justify-content: space-between;
        height: 95%;
    }


    header.contact-header .hero-text h2 {
        font-size: 57px;
        line-height: 61.75px;
        font-size: 66px;
        line-height: 60px;
    }

    header.contact-header .hero-text p {
        font-size: 19.95px;
        line-height: 27.55px;
    }
}


/* @media only screen and (min-width: 1600px) {
    header.contact-header .hero-content {
        top: 60%;
        justify-content: space-around;
    }
    header.contact-header {
        background-size: cover;
    }
}

@media only screen and (min-width: 1650px) {
    header.contact-header {
        padding-bottom: 5rem !important;
    }
}
@media only screen and (min-width: 1700px) {
    header.contact-header {
        padding-bottom: 7rem !important;
    }
}

@media only screen and (min-width: 1770px) {
    header.contact-header {
        padding-bottom: 9rem !important;
    }
}

@media only screen and (min-width: 1820px) {
    header.contact-header {
        padding-bottom: 9.5rem !important;
    }
}

@media only screen and (min-width: 1900px) {
    header.contact-header {
        padding-bottom: 2rem !important;
    }
} */
